const BASE_URL = process.env.VUE_APP_BASE_API
console.log(process.env)
export const url = {
  LOGIN: `${BASE_URL}/api/login`, // 登录
  PHONE_LOGIN: `${BASE_URL}/api/message/login`,
  LOGIN_CODE: `${BASE_URL}/api/captcha`, // 获取验证码
  GET_PHONE_CODE: `${BASE_URL}/api/message`, //获取手机验证码
  GET_HOME_HOME_ALL_SITE: `${BASE_URL}/device/api/device/index/map/list`, // 获取地图全部测站点
  GET_ALL_STATION_TYPE: `${BASE_URL}/device/api/station/type/list`, // 获取测站类型下拉数据
  GET_ALL_DICT_REGION: `${BASE_URL}/api/dict/select/region`, // 获取区域下拉数据
  GET_REPORT_TYPE_LIST: `${BASE_URL}/api/dict/select/function/code`, // 获取上报类型
  GET_ALL_DEVICE_MODEL_SELECT_BY_ID: `${BASE_URL}/device/api/model/manufacturer/`, // 根据厂商id获取设备型号下拉数据
  GET_ALL_BASIN_SELECT: `${BASE_URL}/api/dict/select/basin`, // 获取流域下拉数据
  GET_ALL_DEVICE_MODEL_SELECT: `${BASE_URL}/device/api/model/list`, // 查询时，设备型号下拉数据
  GET_ALL_SITE_SELECT: `${BASE_URL}/device/api/station/list`, //获取测站下拉数据
  GET_DEVICE_MODEL_BY_SITE_AND_MA: `${BASE_URL}/device/api/model/deviceTypeId/manufacturerId/list`, // 根据设备类型和设备厂商获取设备型号下拉数据
  GET_DEVICE_ACCESS_STATUS_SELECT: `${BASE_URL}/device/api/device/accessStatus/list`, // 设备接入状态下拉数据
  GET_ALL_LABEL_TYPE_SELECT: `${BASE_URL}/original/api/labelType/list`, // 获取标签类型下拉数据
  GET_ALL_LABEL_DATA_TYPE_SELECT: `${BASE_URL}/api/dict/select/label/data/type`, // 获取标签数据类型下拉数据
  GET_ALL_LABEL_UNIT_SELECT: `${BASE_URL}/api/dict/select/label/unit`, // 获取标签单位下拉数据
  UPLOAD_USER_HEADER: `${BASE_URL}/api/user/update/user/head`, //修改用户头像
  GET_DICT_SELECT_TYPE: `${BASE_URL}/api/dict/select/type/`, // 获取字典类型下拉
  GET_POWER_MENU_LIST: `${BASE_URL}/api/menu/authorization/list`, //获取权限菜单


  //用户管理
  GET_ALL_USERS: `${BASE_URL}/api/user/list`, //获取所有用户 /system
  GET_PAGE_USERS: `${BASE_URL}/api/user/page/list`, //分页查询用户
  ADD_USER: `${BASE_URL}/api/user/create`,
  DEL_USER_ITEM: `${BASE_URL}/api/user/delete/`,
  UPDATE_USER_ITEM: `${BASE_URL}/api/user/update`,
  RES_USER_PWD: `${BASE_URL}/api/user/reset/password`,
  UPDATE_USER_STATUS: `${BASE_URL}/api/user/update/status`,

  //登录日志
  GET_LOG_LIST: `${BASE_URL}/api/log/page/login/list`,
  DEL_LOG_ITEM: `${BASE_URL}/api/log/`,
  GET_OPTION_TYPE: `${BASE_URL}/api/dict/select/opera/type`,
  GET_OPTION_STATUS: `${BASE_URL}/api/dict/select/system/status`,
  GET_OPTION_LOG: `${BASE_URL}/api/log/page/opera/list`,
  CLEAR_LOG: `${BASE_URL}/api/log/clean/`,
  //数据字典
  GET_DICT_LIST: `${BASE_URL}/api/dict/page/list`,
  UPDATE_DICT_ITEM: `${BASE_URL}/api/dict/update`,
  ADD_DICT_ITEM: `${BASE_URL}/api/dict/create`,
  DEL_DICT_ITEM: `${BASE_URL}/api/dict/delete/`,
  GET_ALL_DICT_TYPE: `${BASE_URL}/api/dict/list`,
  ADD_DICT_DATA_ITEM: `${BASE_URL}/api/dict/data/create`,
  UPDATE_DICT_DATA_ITEM: `${BASE_URL}/api/dict/data/update`,
  GET_DICT_DATA_ITEM: `${BASE_URL}/api/dict/data/page/list`,
  DEL_DICT_OPTION_ITEM: `${BASE_URL}/api/dict/data/delete/`,
  DICT_SORT_ITEM: `${BASE_URL}/api/dict/data/order/`,
  //角色管理
  GET_ROLE_LIST: `${BASE_URL}/api/role/page/select/`,
  DEL_ROLE_ITEM: `${BASE_URL}/api/role/delete/`,
  ADD_ROLE_ITEM: `${BASE_URL}/api/role/create`,
  UPDATE_ROLE_ITEM: `${BASE_URL}/api/role/update`,
  UPDATE_ROLE_STATUS: `${BASE_URL}/api/role/update/status`,
  GET_USER_FOR_ROLEID: `${BASE_URL}/api/role/select/user`,
  ADD_ROLE_USER: `${BASE_URL}/api/role/auth/user`,
  CANCEL_ROLE_USER: `${BASE_URL}/api/role/delete/auth/user`,
  UPDATE_DATA_POWER: `${BASE_URL}/api/role/update/scope`,
  GET_DATA_POWER_INFO: `${BASE_URL}/api/role/select/scope/`,
  GET_NOT_USER: `${BASE_URL}/api/role/select/not/user`,
  GET_ALL_ROLE: `${BASE_URL}/api/role/list`,
  //组织机构
  GET_ALL_DEPT: `${BASE_URL}/api/dept/list`,
  ADD_DEPT_ITEM: `${BASE_URL}/api/dept/create`,
  UPDATE_DEPT_ITEM: `${BASE_URL}/api/dept/update`,
  DEL_DEPT_ITEM: `${BASE_URL}/api/dept/delete/`,
  // 消息管理
  GET_LIMIT_TYPE_SELECT: `${BASE_URL}/message/api/message/config/limit/type/list`, // 限制类型下拉
  GET_NOTIFY_TYPE_SELECT: `${BASE_URL}/message/api/message/config/notify/type/list`, // 通知类型下拉
  GET_MESSAGE_STATUS_SELECT: `${BASE_URL}/message/api/message/config/status/list`, // 状态下拉
  ADD_MESSAGE_SETTING: `${BASE_URL}/message/api/message/config/create`, // 新增消息配置
  EDIT_MESSAGE_SETTING: `${BASE_URL}/message/api/message/config/update`, // 修改消息配置
  GET_MESSAGE_SETTING_LIST: `${BASE_URL}/message/api/message/config/page/list`, // 消息配置分页表格
  GET_MESSAGE_RECORD_LIST: `${BASE_URL}/message/api/message/log/page/list`, // 消息记录分页表格
  GET_MESSAGE_REASON_SELECT: `${BASE_URL}/message/api/message/log/notify/reason/list`, // 通知原因下拉
  //菜单管理
  GET_MENU_INFO: `${BASE_URL}/api/menu/list`, //查询用户菜单信息
  ADD_NEW_MENU: `${BASE_URL}/api/menu/create`, //新增菜单
  DEL_MENU_ITEM: `${BASE_URL}/api/menu/delete/`, //删除菜单
  UPDATE_MENU_ITEM: `${BASE_URL}/api/menu/update`, //修改菜单
  SORT_MENU: `${BASE_URL}/api/menu/order/`, //菜单排序
  SELECT_TYPE: `${BASE_URL}/api/dict/select/type`,
  //新闻管理
  FIND_NEWS: `${BASE_URL}/news/findNews`,//获取全部新闻
  FIND_NEWS_BYID: `${BASE_URL}/news/findNewsById`,//新闻详情
  NEWS_TEMPSAVE: `${BASE_URL}/news/tempSave`, //暂存新闻
  DRAFTS_UPDATE: `${BASE_URL}/news/drafts/update`, //修改新闻
  NEWS_DELETE: `${BASE_URL}/news/delete`, //删除新闻
  NEWS_PUSH: `${BASE_URL}/news/push`, //草稿箱发布新闻
  NEWS_RECYCLE_RECOVER: `${BASE_URL}/news/recycle/recover`, //回收站恢复新闻
  GROUP_ADD: `${BASE_URL}/group/groupAdds`, //新建分栏 ,关键字,来源    
  GROUP_GROUPADDS: `${BASE_URL}/group/groupAdds`, //新建分栏 
  GROUP_DELETE: `${BASE_URL}/group/delete`,  //删除分栏,关键字,来源
  GROUP_UPDATEBYID: `${BASE_URL}/group/updateById`,  //修改分栏名字  
  GROUP_UPDATEORDER: `${BASE_URL}/group/updateOrder`,  //修改分栏顺序  
  //新闻发布
  GROUP_FINDALL: `${BASE_URL}/group/findAll`, //分栏关键字
  FILE_CREATE: `${BASE_URL}/api/file/create`, //缩略图照片
  NEWS_SAVE: `${BASE_URL}/news/save`, //发布新闻
  NEWS_TEMPSAVE: `${BASE_URL}/news/tempSave`, //暂存新闻

  //留言列表
  MESSAGES_PAGE_LIST: `${BASE_URL}/api/messages/page/list`, //留言列表
  MESSAGES_DELETEBYID: `${BASE_URL}/api/messages/deleteById`, //删除留言
  MESSAGES_BYID: `${BASE_URL}/api/messages`, //新闻详情

  //招聘管理
  RECRUITMENT_PAGE_LIST: `${BASE_URL}/api/recruitment/page/list`, //招聘信息列表
  RECRUITMENT_BYID: `${BASE_URL}/api/recruitment/byId`, //招聘信息详情
  RECRUITMENT_DELETEBYID: `${BASE_URL}/api/recruitment/deleteById`, //删除招聘信息

  //数发中心
  SHORTNEWS_SAVE: `${BASE_URL}/shortNews/save`, //发布新闻
  SHORTNEWS_UPDATE: `${BASE_URL}/shortNews/update`, //修改新闻
  SHORTNEWS_FINDNEWS: `${BASE_URL}/shortNews/findNews`, //查询新闻分页
  SHORTNEWS_FINDNEWSSHORTBYID: `${BASE_URL}/shortNews/findNewsShortById/`, //查询新闻详情
}