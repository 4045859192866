import store from '../store/index.js'
const toRouterObj = ()=>{
  const asyncRouter = sessionStorage.asyncRouter ? JSON.parse(sessionStorage.asyncRouter) : []
  let obj = {}
  if(asyncRouter.length){
    asyncRouter.forEach(i=>{
      obj[i.id] = i
    })
  }
  return obj
}
export const draggable = {
  mounted (el) {
    el.style.cursor = 'move'
    el.onmousedown = function (e) { 
      let disx = e.pageX - el.offsetLeft
      let disy = e.pageY - el.offsetTop
      document.onmousemove = function (e) {
        let x = e.pageX - disx
        let y = e.pageY - disy
        let maxX = document.body.clientWidth - parseInt(window.getComputedStyle(el).width)
        let maxY = document.body.clientHeight - parseInt(window.getComputedStyle(el).height)
        if (x < 0) {
          x = 0
        } else if (x > maxX) {
          x = maxX
        }
        if (y < 0) {
          y = 0
        } else if (y > maxY) {
          y = maxY
        }
        el.style.left = x + 'px'
        el.style.top = y + 'px'
      }
      document.onmouseup = function () {
        el.style.cursor = 'inherit'
        document.onmousemove = document.onmouseup = null
      }
    }
  },
}

export const power = {
  mounted(el,binding){
    const asyncRouterObj = toRouterObj()
    let button = asyncRouterObj[store.state.activeMenuId]?.button || []
    console.log(button)
    if(!button.includes(binding.arg)){
      // el.parentNode.removeChild(el)
      el.style.display = 'none'
    }
  }
}