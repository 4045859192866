import {
  createStore
} from "vuex";
import { filterStatus } from "../utils/common";
export default createStore({
  state: {
    topNavIndex: sessionStorage.topNavIndex ?? 0,
    activeMenu: sessionStorage.activeMenu ?
      JSON.parse(sessionStorage.activeMenu) : {},
    activeMenuId: Number(sessionStorage.activeMenuId) ?? "",
    menuData: [],
    hoverMenu: {},
    siteTypeList: [],
    loading: 0,
    regionList: [],
    deviceTypeList: [],
    manufactorList: [],
    userList: [],
    pansList: sessionStorage.pansList ? JSON.parse(sessionStorage.pansList) : [],
    isHoverMenu: false,
    inHome: false,
    isScaleMenu: false,
    tableHeadObj: sessionStorage.tableHeadObj ? JSON.parse(sessionStorage.tableHeadObj) : {},
    userInfo: sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {},
    homeTerm: {}
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setIsHoverMenu(state, val) {
      state.isHoverMenu = val
    },
    setUserHeader(state, val) {
      let user = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : ''
      state.userInfo.icon = val
      if (user) {
        user = { ...user, icon: val }
        sessionStorage.userInfo = JSON.stringify(user)
      }
    },
    setActiveMenu(state, obj) {
      if (obj.onlyIndex) {
        state.topNavIndex = obj.id;
      } else {
        state.activeMenu = obj;
        state.topNavIndex = obj.id;
        state.activeMenuId = obj.activeMenuId;
      }
      console.log(state.topNavIndex)
    },
    setMenuData(state, arr) {
      arr = filterStatus(arr)
      state.menuData = arr
    },
    setHoverMenu(state, data) {
      state.hoverMenu = data
    },
    setInHome(state, val) {
      state.inHome = val
    },
    setPansList(state, obj) {
      if (Array.isArray(obj)) {
        state.pansList = obj
      } else {
        if (state.pansList.length) {
          let hasItem = state.pansList.filter(item => item.id == obj.id)
          hasItem.length ? '' : state.pansList.push(obj)
        } else {
          state.pansList.push(obj)
        }
      }
      sessionStorage.pansList = JSON.stringify(state.pansList)
    },
    setIsScaleMenu(state, val) {
      state.isScaleMenu = val
    },
    setActiveMenuId(state, id) {
      state.activeMenuId = id;
    },
    setSiteTypeList(state, arr) {
      state.siteTypeList = arr
    },
    setRegionList(state, arr) {
      state.regionList = arr
    },
    setDeviceTypeList(state, arr) {
      state.deviceTypeList = arr
    },
    setManufactorList(state, arr) {
      state.manufactorList = arr
    },
    setUserList(state, arr) {
      state.userList = arr
    },
    setTableHeadObj(state, obj) {
      state.tableHeadObj = obj;
      sessionStorage.tableHeadObj = JSON.stringify(state.tableHeadObj);
    },
    setUserInfoObj(state, obj) {
      state.userInfo = obj;
      sessionStorage.userInfo = JSON.stringify(state.userInfo);
    },
    setHomeTerm(state, obj) {
      let n = Math.random()
      state.homeTerm = { count: n, ...obj };
    }
  }
})