import { createRouter, createWebHashHistory } from "vue-router";
import NProgress from 'nprogress'
const view = {
  blank: () => import("@/layouts/blankLayout/blankLayout.vue"),
  admin: () => import("@/layouts/adminLayout/adminLayout.vue"),
  page: () => import("@/layouts/pageLayout/pageLayout.vue"),
};
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", component: () => import("../pages/login/login.vue") },
    { path: "/404", component: () => import("@/components/404.vue") },
    {
      path: "/admin",
      component: view.admin,
      name:'admin',
      children: [],
    },
    {
      path: "/view",
      component: view.blank,
      name:'view',
      children: [],
    },
    { path: "/:catchAll(.*)", redirect: "/404" },
  ],
});
NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()
  next()
})
router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})
export default router;
