export default {
  system: {
    path: 'system',
    meta: {
      parent: 'admin'
    }
  },
  newsDy: {
    path: 'newsDy',
    meta: {
      parent: 'admin'
    }
  },
  leavePage: {
    path: 'leaveMsgelist',
    meta: {
      parent: 'admin'
    }
  },
  trends: {
    path: 'trendsList',
    meta: {
      parent: 'admin'
    }
  },
  recruitment: {
    path: 'recruitmentList',
    meta: {
      parent: 'admin'
    }
  },
  digitalEco: {
    path: 'digitalEcoList',
    meta: {
      parent: 'admin'
    }
  },
  organization: {
    path: 'organization',
    component: () => import("../pages/system/organiztion/organiztion.vue"),
    meta: {
      parent: "admin"
    },
  },
  user: {
    path: 'user',
    component: () => import("../pages/system/userMange/userMange.vue"),
    meta: {
      parent: "admin"
    }
  },
  menu: {
    path: 'menu',
    component: () => import("../pages/system/menuMange/menuMange.vue")
  },
  role: {
    path: 'role',
    component: () => import("../pages/system/roleMange/roleMange.vue")
  },
  configRole: {
    path: 'configRole',
    component: () => import("../pages/system/roleMange/configRole.vue"),
    meta: {
      parent: "admin"
    }
  },
  sysLog: {
    path: 'sysLog',
    component: () => import("../pages/system/systemLog/systemLog.vue"),
    meta: {
      parent: "admin"
    }
  },
  dictionary: {
    path: 'dict',
    component: () => import("../pages/system/dictionary/dictionary.vue"),
    meta: {
      parent: "admin"
    }
  },
  newsManage: {
    path: 'newsManage',
    component: () => import("../pages/system/newsManage/index.vue"),
    meta: {
      parent: "admin"
    }
  },
  newsmgeIndex: {
    path: 'newsmgeIndex',
    component: () => import("../pages/newsDy/newsAdmin/newsmgeIndex.vue"),
    meta: {
      parent: "admin"
    }
  },
  pubArticles: {
    path: 'pubArticles',
    component: () => import("../pages/newsDy/newsAdmin/pubArticles.vue"),
    meta: {
      parent: "admin"
    }
  },
  draftBox: {
    path: 'draftBox',
    component: () => import("../pages/newsDy/newsAdmin/draftBox.vue"),
    meta: {
      parent: "admin"
    }
  },
  recycleBin: {
    path: 'recycleBin',
    component: () => import("../pages/newsDy/newsAdmin/recycleBin.vue"),
    meta: {
      parent: "admin"
    }
  },
  leaveMsgelist: {
    path: 'leaveMsgelist',
    component: () => import("../pages/leaveWord/leaveMsgelist.vue"),
    meta: {
      parent: 'admin'
    }
  },
  trendsList: {
    path: 'trendsList',
    component: () => import("../pages/trends/trendsList.vue"),
    meta: {
      parent: 'admin'
    }
  },
  recruitmentList: {
    path: 'recruitmentList',
    component: () => import("../pages/recruitment/recruitmentList.vue"),
    meta: {
      parent: 'admin'
    }
  },
  digitalEcoList: {
    path: 'digitalEcoList',
    component: () => import("../pages/digitalEco/digitalEcoList/digitalEcoList.vue"),
    meta: {
      parent: 'admin'
    }
  },
  digitalArticle: {
    path: 'digitalArticle',
    component: () => import("../pages/digitalEco/digitalArticle/digitalArticle.vue"),
    meta: {
      parent: "admin"
    }
  },
  digiRecycleList: {
    path: 'digiRecycleList',
    component: () => import("../pages/digitalEco/digiRecycle/digiRecycleList.vue"),
    meta: {
      parent: "admin"
    }
  },
};