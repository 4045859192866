<template>
  <a-config-provider :locale="zhCN">
    <a-spin tip="" :spinning="store.state.loading === 1">
      <router-view></router-view>
    </a-spin>
  </a-config-provider>
</template>

<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { onMounted, provide } from "vue";
import { useStore } from 'vuex';
import { changeSize, refreshWindow, } from "./utils/common.js";
import { url } from './services/api.js'; // 导入封装的api
provide('url', url)
const store = useStore()
onMounted(async () => {
  document.getElementById('loader-wrapper').remove()
  refreshWindow();
  window.onresize = () => {
    changeSize();
  };
});
</script>
<style lang="less" scoped>
:global(html) {
  touch-action: none;
}

:global(.edit-modal .ant-modal-body) {
  // 因表单自带了margin-bottom
  padding-bottom: 0px;
}

:global(.edit-modal .ant-modal-footer) {
  // 按钮居中
  text-align: center;
}

:global(.edit-modal .ant-modal-footer div .ant-btn:first-child) {
  // 取消按钮样式改变
  color: #1890FC;
  background: #CAEEFF;
}

:global(.cropper) {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10 !important;
}
</style>
