import axios from "axios";
import { message } from "ant-design-vue";
import Qs from 'qs' // 序列化
import router from '../router/index'
const service = axios.create({
  baseUrl: process.env.VUE_APP_BASE_API,
  timeout: 20000
})

// 跨域认证信息 header 名
service.defaults.withCredentials = true
service.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
}
/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
export function request(url, method, params, config) {
  switch (method) {
    case METHOD.DELETE:
      return service.delete(url, {
        params,
        ...config
      })
    case METHOD.GET:
      return service.get(url, {
        params,
        ...config
      })
    case METHOD.POST:
      return service.post(url, Qs.stringify(params), {
        ...config,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
    case 'form':
      let form = new FormData()
      for (let key in params) {
        form.append(key, params[key])
      }
      return service.post(url, form, {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    case 'str':
      let conf = config && config.headers ? config.headers : {}
      return service.post(url, params, {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          ...conf
        },
      })
    case 'flie':
      return service.get(url, {
        responseType: 'arraybuffer'
      })
    case METHOD.PUT:
      return service.put(url, params, {
        ...config,
        headers: {
          'Content-Type': 'application/json'
        },
      })
    default:
      return service.get(url, {
        params,
        ...config
      })
  }
}

//请求拦截
service.interceptors.request.use(
  config => {
    //添加请求头
    let tKey = sessionStorage.getItem('tKey')
    let tVal = sessionStorage.getItem('tVal')
    config.headers[tKey] = tVal
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
//响应拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code != 200) {
      message.error(res.message)
      if (res.code == 'A00004') {
        router.push('/')
      }
      return Promise.reject(res.message)
    } else {
      return Promise.resolve(res.data)
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
export default service