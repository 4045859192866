import store from "../store";
import staticMap from "../router/routerMap.js";
import router from "../router/index.js";
import { request } from './request';
const view = {
  content: () => import("@/layouts/empty/index.vue"),
};
let pidList = []
let openKeysList = []
//页面rem适配
export const changeSize = (charts = undefined) => {
  let docWidth = document.documentElement.clientWidth;
  document.documentElement.style.fontSize = (docWidth / 1440) * 100 + "px";
  document.body.style.fontSize = "13px";
  if (charts) {
    charts.resize()
  }
};

//构造树形结构
export const toTreeData = (arr, pid = -1) => {
  let temp = [];
  for (let i = 0; i < arr.length; i++) {
    arr[i].key = arr[i].id;
    if (arr[i].parentId == pid) {
      temp.push(arr[i]);
      arr[i].children = toTreeData(arr, arr[i].id);
    }
  }
  return temp;
};

//构造异步路由
export const renderRouter = (arr) => {
  //本地路由和响应路由对比，进行过滤,加上path和component属性
  let newArr = arr
    .map((item) => {
      item.title = item.menuName;
      item.name = item.tag;
      if (staticMap[item.tag]) {
        staticMap[item.tag].component ? '' : item.component = view.content;
        return { ...item, ...staticMap[item.tag] };
      }
    })
    .filter((item) => (item ?? item));
  //获取本地需要修改的子路由，主要是admin和view路由的修改
  let staticRouterArr = router.getRoutes();
  let addRouterObj = staticRouterArr.filter((item) => {
    if (item.name == "admin" || item.name == "view") {
      return item;
    }
  });
  //构造树形结构
  let tree = toTreeData(newArr);
  tree.forEach((item) => {
    if (item.meta.parent == "admin") {
      addRouterObj[0].children.push(item);
    } else {
      addRouterObj[1].children.push(item);
    }
  });
  addRouterObj.forEach((item) => {
    router.addRoute(item);
  });
  store.commit('setMenuData', tree)
};
//点击菜单栏切换页面
export const toPage = (name, id, title) => {
  sessionStorage.activeMenuId = id
  store.commit('setActiveMenuId', id)
  store.commit('setPansList', { name, id, title })
  console.log(router);
  console.log({ name, id, title });
  router.push({ name })
}
//页面刷新重构路由
export const refreshWindow = () => {
  let asyncRouter = sessionStorage.getItem("asyncRouter")
    ? JSON.parse(sessionStorage.getItem("asyncRouter"))
    : false;
  if (asyncRouter) {
    let path = window.location.hash.replace("#", "");
    renderRouter(asyncRouter);
    router.push(path);
  }
};

/**
 * fn : 获取常用下拉数据
 * params : 测站类型（siteType）区域（region）设备类型（deviceType）设备厂家（manufactor）设备型号(deviceModel) 全部用户(user) 设备分组(deviceGroup)
 */
export const getSiteTypeList = (http) => {
  let res = request(http.GET_ALL_STATION_TYPE, 'get');
  return res;
};
export const getRegionList = (http) => {
  let res = request(http.GET_ALL_DICT_REGION, 'get');
  return res;
};
export const getDeviceTypeList = (http) => {
  let res = request(http.GET_ALL_DEV_TYPE, 'get');
  return res;
};
export const getManufactorList = (http) => {
  let res = request(http.GET_ALL_DEV_FACTUER, 'get');
  return res;
};
export const getDeviceModelList = (http) => {
  let res = request(http.GET_ALL_DEVICE_MODEL_SELECT, 'get');
  return res;
};
export const getDeviceGroupList = (http) => {
  let res = request(http.GET_ALL_DEV_GROUP, 'get');
  return res;
};
export const getUserList = (http) => {
  let res = request(http.GET_ALL_USERS, 'get');
  return res;
};
export const getFristMenuPath = (obj) => {
  if (obj.children.length) {
    return getFristMenuPath(obj.children[0])
  }
  else {
    return { name: obj.name, id: obj.id, title: obj.title }
  }
}
// 获取两个JSON数组的相同项
export const getJsonArrEqual = (arr1, arr2) => {
  var newArr = [], kvIndex = {};
  for (var i = 0; i < arr1.length; i++) {
    for (var j = 0; j < arr2.length; j++) {
      if (arr1[i].title == arr2[j].title) {
        var item
        if (kvIndex[arr1[i].title] == undefined) {
          kvIndex[arr1[i].title] = newArr.length;
          item = {};
          for (var attr in arr1[i]) item[attr] = arr1[i][attr];
          newArr[kvIndex[arr1[i].title]] = item;
        } else {
          item = newArr[kvIndex[arr1[i].title]];
          for (var attr in arr2[j]) item[attr] = arr2[j][attr];
        }
      }
    }
  }
  return newArr
}
export const filterStatus = (arr) => {
  arr.forEach((item, index) => {
    if (item.children?.length) {
      filterStatus(item.children)
    } else {
      if (item.status == 0) {
        arr.splice(index, 1)
      }
    }
  })
  return arr
}

// export const getAssetsHomeFile = (url) => {
//   let path = `../assets/svg/${url}.svg`;
//   const modules = import.meta.globEager("../assets/svg/*");
//   return modules[path].default;
// }

export const getParentKey = (array, id) => {
  let parentArray = [];
  console.log(parentArray)
  if (array.length == 0) {
    return;
  }

  function recursion(arrayNew, id) {
    for (let i = 0; i < arrayNew.length; i++) {
      let node = arrayNew[i];
      if (node.id == id) {
        parentArray.unshift(node.id);
        recursion(array, node.parentId);
        break;
      } else {
        if (node.children) {
          recursion(node.children, id);
        }
      }
    }
    return parentArray;
  }
  parentArray = recursion(array, id);
  return parentArray;
}