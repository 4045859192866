import { createApp } from 'vue'
import { createWebHashHistory, createRouter } from 'vue-router'
import NProgress from 'nprogress'
import App from './App.vue'
import router from './router'
import { changeSize } from './utils/common.js';
import { draggable, power } from './utils/directive.js'
import store from './store'
import 'nprogress/nprogress.css'
import './assets/common/animate.css'
import './style/common.less'
import antd from 'ant-design-vue'
import "ant-design-vue/dist/antd.less"
import infiniteScroll from 'vue3-infinite-scroll-good'
import preventReClick from './utils/preventReClick'
import Cropper from "vue3-cropper";
import 'vue3-cropper/lib/vue3-cropper.css';
import 'default-passive-events'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import antIcons from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear' // 导入插件
import 'dayjs/locale/zh-cn' // 导入本地化语言


let app = null
changeSize()
// 注册组件

dayjs.extend(isLeapYear) // 使用插件
dayjs.locale('zh-cn') // 使用本地化语言
const render = (props = {}) => {
  let { container } = props
  app = createApp(App)
  Object.keys(antIcons).forEach(key => {
    app.component(key, antIcons[key])
  })
  app.directive('power', power)
  app.use(antd).use(infiniteScroll).use(store).use(router).use(preventReClick).use(Cropper).mount(container ? container.querySelector('#app') : '#app')
}

// createApp(App).use(store).use(router).mount('#app')

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap(props) {
  console.log(props)
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  app = null
}